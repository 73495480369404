import React from 'react'
import '../css/footer.css'
import { ReactComponent as Logo } from '../assets/footerLogo.svg'
function Footer() {
  let currentYear = new Date().getFullYear()

  return (

    <footer className="bg-black  shadow dark:bg-gray-900 ">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a  className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse no-underline">
            {/* <img src="https://flowbite.com/docs/images/logo.svg" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse" /> */}
            <Logo />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white no-underline">Raul Flores</span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a href="#" className="no-underline me-4 md:me-6 text-white">About</a>
            </li>
            <li>
              <a href="https://www.termsfeed.com/live/7ed91749-da00-4992-b7c2-b03f18fb6808" className="no-underline me-4 md:me-6 text-white">Privacy Policy</a>
            </li>
            {/* <li>
              <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>
            </li> */}
            <li>
              <a href="/contact" className="no-underline text-white">Contact</a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8 no-underline" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 no-underline">
          © {currentYear} <a href="https://www.linkedin.com/in/raulfr99/" target="_blank" className="no-underline text-white">raulfr99</a>. Made with 💗 by me.
        </span>
      </div>
    </footer>



  )
}

export default Footer
