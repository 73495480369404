import React from 'react';
import '../css/work.css';
import ExperienceItem from './ExperienceItem';
const Work = () => {
  const EXPERIENCE = [
    {
      date: '2022 - Present...',
      title: 'IDC Online - Fullstack Developer',
      description: 'Web development, API maintenance, web platforms, backend systems and development of a mobile application in React Native, as well as its publication in stores. Introduction and development of functions to ERP and CRM systems.',
      link: '#',
      activities: [
        'Development and publication of the mobile application in the stores.',
        'Maintenance of existing APIs and creation of new services.',
        'Maintenance and development on AWS',
        'SQL database management',
        'Web development and deployment', 
        'Data usage and Odoo development',
        'Creation and use of data and backend services.',
        'Testing and debugging.'
      ],
    },
    {
      date: '2021 - 2021',
      title: '4Plus1 Creative - Frontend Developer',
      description: 'Develop web pages and systems, using javascript as main technology, APIs and integration in backend systems as well as database management.',
      link: '#',
      activities: [
        'Web site design and implementation.',
        'Maintenance of backend systems.',
        'Database management.',
      ],
    },
    {
      date: '2020 - 2020',
      title: 'Marzhal Hackers - Mobile Developer',
      description: 'I developed a mobile application in React Native and a web platform, it was my internship project and my first formal job.',
      link: '#',
      activities: [
        'Development of the mobile application in React Native.',
        'Creation of a web platform.',
        'Testing and debugging.',
      ],
    },
  ];
  




  return (
    <div className="flex flex-col items-center mx-auto bg-black py-10">
      <h2 className="text-2xl font-bold text-center text-white mb-6">Laboral Experience</h2>
      <ol className="relative border-s border-gray-200 dark:border-gray-700 ml-3">
        {EXPERIENCE.map((exp, index) => (
          <ExperienceItem key={index} {...exp} />
        ))}
      </ol>
    </div>
  );
};

export default Work;
