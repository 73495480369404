export const links = [
    {
        id: 1,
        text: 'HOME',
        url: 'home'
    },
    {
        id: 2,
        text: 'ABOUT',
        url: 'about'
    },
    {
        id: 3,
        text: 'EXPERIENCE',
        url: 'mywork'
    },
    {
        id: 4,
        text: 'WORK',
        url: 'contact'
    },
]