import React from 'react'
import '../css/home.css'

import Page from '../components/Page.js'
import About from '../components/About.js'
import Work from '../components/Work.js'
import Contact from '../components/Contact.js'
import Footer from '../components/Footer.js'

function Home() {

    return (
        <div>
            {/* <Header/> */}
            <section className="scroll" id="home">
                <Page/>
            </section>
            <section className="scroll" id="about">
                <About/>
            </section>
            <section className="scroll" id="mywork">
                <Work/>
            </section>
            <section className="scroll" id="contact">
                <Contact/>
            </section>
            <section className="scroll" id="footer">
                <Footer/>
            </section>
            </div>
    )
}

export default Home
