import React, { useState } from 'react';
import Modal from './Modal'; // Asegúrate de importar el Modal

const ExperienceItem = ({ title, description, link, date, activities }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <li className="mb-10 ms-4 w-full max-w-[1100px] px-4 sm:px-6 break-words">
        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>

        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          {date}
        </time>

        <h3 className="text-lg font-semibold text-yellow-200 mt-2">
          {title}
        </h3>

        <p className="mb-4 text-base font-normal text-gray-200">
          {description}
        </p>

        <button
          onClick={openModal}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-black bg-white border border-gray-200 rounded-lg hover:bg-gray-600 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
        >
          Read more
          <svg className="w-3 h-3 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </button>
      </li>

      <Modal isOpen={isModalOpen} onClose={closeModal} project={{ title, description, activities }} />
    </>
  );
};

export default ExperienceItem;
