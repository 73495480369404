import React, { useState } from 'react'
import { FiX } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";


import { Link } from 'react-scroll'
import logo2 from '../assets/logo2.png'
import '../css/header.css'
import { links } from './data'
function Header() {
    let path = true
    if (window.location.pathname !== "/") {
        path = false
    }
    let navigate = useNavigate();
    const [navbar, setNavbar] = useState(false)
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [homeLogo, setHomeLogo] = useState(false)


    const changeBackground = () => {
        if (window.scrollY >= 65) {
            setNavbar(true)
            setHomeLogo(true)
        } else {
            setNavbar(false)
            setHomeLogo(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <nav className={path ? (navbar ? 'navigation active ' : 'navigation') : 'staticNavigation'}>
            <Link to='home'>
                <img src={logo2} className="logo" alt='logo portfolio raul flores' onClick={() => {
                    navigate("/");
                }} />
            </Link>
            <div className={
                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <button
                    className="mobileButton"
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded);
                    }}
                >
                    {isNavExpanded ? (
                        <FiX className="text-gray-200" size="2.4rem" />
                    ) : (
                        <CiMenuBurger className="text-gray-200" size="1.6rem" />
                    )}
                </button>

                <ul>
                    {path
                        ?
                        links.map((link) => {
                            return (
                                <li key={link.id}>
                                    <Link className={'no-underline text-white'} to={link.url} spy={true} smooth={true} offset={-80} duration={200} key={link.id}
                                        onClick={() => {
                                            setIsNavExpanded(!isNavExpanded);
                                        }}> {link.text}
                                    </Link>
                                </li>
                            )
                        })
                        :
                        <li>
                            <Link to="home" spy={true} smooth={true} offset={0} duration={500}
                                onClick={() => {
                                    setIsNavExpanded(!isNavExpanded);
                                }}>
                                <a onClick={() => { navigate("/"); }} href="/">
                                    Home
                                </a>
                            </Link>
                        </li>
                    }
                </ul>
            </div>
        </nav>
    )
}

export default Header
