const Modal = ({ isOpen, onClose, project }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          X
        </button>
        
        {project ? (
          <>
            <h2 className="text-2xl font-bold mb-4">{project.title}</h2>
            <p className="text-lg mb-4">{project.description}</p>
            
            {project.activities && (
              <ul className="list-disc pl-5 mb-4">
                {project.activities.map((activity, index) => (
                  <li key={index} className="text-gray-600">{activity}</li>
                ))}
              </ul>
            )}
            
            {/* Links Section */}
            {project.links && (
              <div className="flex gap-4 mt-4">
                {project.links.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-2 bg-gray-400 rounded hover:bg-gray-200 transition"
                  >
                    <link.icon className="w-5 h-5" />
                    <span className="text-sm text-gray-700">Visit Link</span>
                  </a>
                ))}
              </div>
            )}
          </>
        ) : null}

        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-[#000] text-white hover:bg-gray-600 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
