import React, { useState } from 'react';
import '../css/about.css';
import me2 from '../assets/me.jpeg';
import cv from '../assets/CV.pdf';
import { BiLogoJavascript, BiLogoReact, BiLogoNodejs, BiLogoGithub, BiLogoTypescript, BiLogoVuejs, BiLogoFirebase, BiLogoHtml5, BiLogoCss3, BiLogoBootstrap } from "react-icons/bi";
import { SiExpo } from "react-icons/si";
import { Fade, Reveal } from "react-awesome-reveal";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();
const About = () => {
    const [showModal, setShowModal] = useState(false);
    const file = '../assets/CV.pdf';

    const handleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div className="about">
            <h2 className='titleText'>ABOUT ME</h2>
            <span className='minDiv' />
            <div className='aboutContent'>
                <div className='divB'>
                    <Reveal triggerOnce>
                        <p className="abText">My name is Raúl, and it's a pleasure.</p>
                        <p className="desText">
                            I started my career 3 years ago. I have worked with some companies as a frontend and backend developer on various projects. Coding websites, mobile apps, systems, backend, and cloud services among others. I am very curious, dedicated, and 100% self-taught. Know my skills.
                        </p>
                        <button className='cvButton' onClick={handleModal}>View My CV</button>
                    </Reveal>
                </div>
                <div className='divA'>
                    <Fade direction='right' triggerOnce>
                        <img src={me2} className='meImg' alt="" />
                    </Fade>
                </div>
            </div>
            <p style={{
                color: '#fff',
                fontWeight: 'bold',
                alignSelf: 'center',
                marginTop: '5%',
                fontSize: '1.5em',
                textAlign: 'center'
            }}>
                These are the technologies I have been working on.
            </p>
            <div className='skillContainer'>
                <div className='skillItem'>
                    <BiLogoJavascript size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>Javascript</p>
                </div>
                <div className='skillItem'>
                    <BiLogoReact size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>React</p>
                </div>
                <div className='skillItem'>
                    <BiLogoReact size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>React Native</p>
                </div>
                <div className='skillItem'>
                    <BiLogoNodejs size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>NodeJS</p>
                </div>
                <div className='skillItem'>
                    <BiLogoTypescript size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>TypeScript</p>
                </div>
                <div className='skillItem'>
                    <BiLogoGithub size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>Github</p>
                </div>
                <div className='skillItem'>
                    <BiLogoVuejs size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>VueJS</p>
                </div>
                <div className='skillItem'>
                    <BiLogoFirebase size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>FireBase</p>
                </div>
                <div className='skillItem'>
                    <BiLogoHtml5 size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>HTML</p>
                </div>
                <div className='skillItem'>
                    <BiLogoCss3 size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>CSS</p>
                </div>
                <div className='skillItem'>
                    <BiLogoBootstrap size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>Bootstrap</p>
                </div>
                <div className='skillItem'>
                    <SiExpo size={60} color='fff' style={{ margin: 'auto' }} />
                    <p className='skillText'>Expo CLI</p>
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm" onClick={handleModal}>
                    <div className="relative bg-white p-6 rounded-lg  max-w-lg sm:max-w-2xl lg:max-w-4xl max-h-[80vh] overflow-y-auto">
                        <div className="flex justify-between mb-4">
                            <button
                                onClick={handleModal}
                                className="text-white bg-[#AB09A4] hover:bg-purple-700 p-2 rounded-md"
                            >
                                Close
                            </button>
                            <button
                                onClick={() => window.open(cv, '_blank')}
                                className="text-white bg-[#AB09A4] hover:bg-purple-700 p-2 rounded-md"
                            >
                                Download CV
                            </button>
                        </div>
                        <div className="flex justify-center w-full">
                            <Document
                                file={cv}
                                onLoadError={(error) => console.error("Error loading PDF:", error)}
                                onLoadSuccess={() => console.log("PDF loaded successfully")}
                            >
                                <Page pageNumber={1} />
                            </Document>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default About;
