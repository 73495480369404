import React from 'react'
import '../css/page.css'
import Lottie from "lottie-react";
import anTest from "../assets/devAnimation.json";
import StarfieldAnimation from 'react-starfield-animation'
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import {Reveal} from "react-awesome-reveal";
import {Link} from 'react-scroll'
import { useNavigate } from 'react-router';
function Page() {
    let navigate = useNavigate();
    return (
            <div className="home">
            <StarfieldAnimation
                style={{
                position: 'absolute',
                width: '100%',
                height: '100vh',
                zIndex: 0,
                }}
                numParticles={300}
            />
                <div className='leftHome'>
                    <Reveal style={{width: '100%', display: 'flex', alignSelf: 'center' }} triggerOnce={true} >
                            <p className='leftTitle'>Hello, I'm <span style={{color: '#B209AA',}}>Raúl</span></p>
                    </Reveal>
                    <p className='leftTitle'>Im a Fullstack Developer💻</p>
                    <p className='leftText'>I like to build applications, websites, software and everything possible.</p>
                    <div className='socialIcons'> 
                        <a href='https://github.com/raulfr99' style={{margin: '2%'}} target="_blank" rel="noreferrer">
                            <AiFillGithub  size={60} color='#fff' className='socialIcon' />
                        </a>
                        <a href='https://www.linkedin.com/in/raulfr99/' style={{margin: '2%'}} target="_blank" rel="noreferrer">
                            <AiFillLinkedin size={60} color='#fff' className='socialIcon' />
                        </a>                            
                    </div>
                    <Link to='contact' className='workButton'>VIEW MY WORK</Link>
                </div>
                <div className='rightHome'>
                    <div className='gradient' > </div>
                    <Lottie animationData={anTest} loop={true} className='animatedIcon' />
                </div>
            </div>
    )
}

export default Page
