import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Node from '../assets/icons/Node';
import ReactJS from '../assets/icons/React';
import Android from '../assets/icons/Android';
import Apple from '../assets/icons/Apple';
import AWS from '../assets/icons/AWS';
import project1Img from '../assets/projects/project1.webp';
import project2Img from '../assets/projects/project2.webp';
import Modal from './Modal';

const TAGS = {
  REACT: {
    name: 'React',
    class: 'bg-black text-white',
    icon: ReactJS
  },
  REACTN: {
    name: 'React Native',
    class: 'bg-black text-white',
    icon: ReactJS
  },
  NODE: {
    name: 'Node JS',
    class: 'bg-white text-black',
    icon: Node
  },
  ANDROID: {
    name: 'Android',
    class: 'bg-white text-black',
    icon: Android
  },
  APPLE: {
    name: 'iOS',
    class: 'bg-black text-white',
    icon: Apple
  },
  AWS: {
    name: 'AWS',
    class: 'bg-white text-black',
    icon: AWS
  },
};

const PROJECTS = [
  {
    title: 'To do app and platform',
    description: 'Created with HTML, CSS and JavaScript',
    image: project1Img,
    link: 'https://www.futbin.com/',
    tags: [TAGS.REACT, TAGS.NODE, TAGS.AWS, TAGS.REACTN]
  },
  {
    title: 'IDC App',
    description: 'Created with React Native, CSS and JavaScript',
    image: project2Img,
    link: 'https://www.futbin.com/',
    tags: [TAGS.REACTN, TAGS.NODE, TAGS.ANDROID, TAGS.APPLE]
  },
];

const MODAL_CONTENT = {
  project1: {
    title: 'Task Management App',
    description: 'I am working on my own project, a to do multi platform application, with a mobile application and a website that you can use whenever you need it. They will be synchronized all the time so what you do in the app you can see it on the website and vice versa.',
    links: [
      // {
      //   icon: Android,
      //   url: 'https://play.google.com/store/apps/details?id=com.example.project1'
      // }
    ]
  },
  project2: {
    title: 'IDC Mobile App',
    description: 'First project I made in my current job, an application for users and customers of the company. Developed from scratch in React Native using Expo, published in Apple and Google Play stores.',
    links: [
      {
        icon: Android,
        url: 'https://play.google.com/store/apps/details?id=mx.idconline.idcapp&hl=es_MX'
      },
      {
        icon: Apple,
        url: 'https://apps.apple.com/mx/app/app-idc/id6464002645'
      },
    ]
  }
};

function Contact() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (projectKey) => {
    setSelectedProject(MODAL_CONTENT[projectKey]); 
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <div className="w-full flex flex-col bg-cover bg-left-top" style={{ backgroundImage: 'url(https://themes.muffingroup.com/be/digital/wp-content/uploads/2015/07/home_digital_pattern_1.png)' }}>
      <div className="flex flex-col w-full bg-white mx-auto my-16 px-6 pb-10 sm:px-8 md:px-12 lg:px-16">
        <p className="mt-10 text-center text-3xl font-bold sm:text-4xl">RECENT WORK...</p>
        <p className="text-center w-full sm:w-1/2 mx-auto text-xl font-light mb-10">
          These are the projects that I've been working on.
        </p>
        <p className="text-center w-full sm:w-1/2 mx-auto text-l font-light mb-10">
          Click on the pictures to see more information
        </p>

        {/* Proyectos */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 auto-cols-fr">
          {PROJECTS.map((project, index) => (
            <article key={index} className="p-4 bg-[#1b1b1d] rounded-lg shadow-lg ">
              <h3 className="text-2xl font-semibold text-yellow-200 mb-2">{project.title}</h3>
              <p className="text-lg mb-4 text-white">{project.description}</p>
              <ul className="flex flex-wrap gap-x-2 gap-y-1 mb-2">
                {project.tags.map((tag, i) => (
                  <li key={i}>
                    <span className={`flex items-center gap-x-2 rounded-full text-xs ${tag.class} py-1 px-2`}>
                      <tag.icon className="w-4 h-4" />
                      {tag.name}
                    </span>
                  </li>
                ))}
              </ul>
              <img 
                className="rounded shadow-2xl w-full transition-all duration-300 hover:scale-95 cursor-pointer" 
                src={project.image} 
                alt={project.title} 
                loading="lazy" 
                onClick={() => openModal(`project${index + 1}`)}  
              />
            </article>
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} project={selectedProject} />
    </div>
  );
}

export default Contact;
